import React, { useState } from "react";
import {
  FormOptions,
  FormProps,
  SubmitMessage,
} from "../../_Utilities/MyForm/FormTypes";
import MyForm from "../../_Utilities/MyForm/MyForm";
import { ReactSetter } from "../../_Utilities/ReactAliases";
import {
  httpProm,
  HTTP_Res,
  API_ENDPOINT,
  validateEmail,
} from "../../_Utilities/utils";
import { TOKEN_STORE, UserI } from "../../data-types";
import GoogleButton from "./GoogleButton";
import styles from "./LoginForm.module.scss";

type LoginFormFields = {
  email: string;
  password: string;
};

type CreateForm = LoginFormFields & {
  alias: string;
};

interface Props {
  setUser: ReactSetter<UserI | null>;
}

export default function LoginForm({ setUser }: Props) {
  const [loginForm, setLoginForm] = useState<LoginFormFields>({
    email: "",
    password: "",
  });
  const [createForm, setCreateForm] = useState<CreateForm>({
    email: "",
    password: "",
    alias: "",
  });
  const [loginOrCreate, setLoginOrCreate] = useState("login");
  const [submitMessage, setSubmitMessage] = useState<SubmitMessage>();

  const loginSuccess = (res: HTTP_Res) => {
    try {
      const { token, user } = JSON.parse(res.text);
      if (!token || !user) {
        console.log("Weird response from server");
        console.log(res);
        return;
      }
      console.log(user);
      localStorage.setItem(TOKEN_STORE, token);
      setUser(user);
    } catch (err) {
      console.log(res);
      console.warn(err);
    }
  };

  const displayErr = (res: HTTP_Res) =>
    res.text && setSubmitMessage({ type: "error", message: res.text });

  // These are the same for both login and create
  const emailAndPwOptions: FormOptions = {
    email: {
      placeholder: "user@example.com",
      validator: (val) => (!validateEmail(val) ? "Invalid email" : ""),
      type: "email",
    },
    password: {
      validator: (val) =>
        val.length < 5 ? "Password must be at least 5 characters" : "",
      type: "password",
    },
  };

  // Just making things a little DRY-er
  const postToSockets = (path: string, body: any, e: React.FormEvent) => {
    const method = "POST";
    e.preventDefault();
    return httpProm(`${API_ENDPOINT}${path}`, { method, body });
  };

  const loginFormProps: FormProps<LoginFormFields> = {
    actionButton: "Login",
    form: loginForm,
    setForm: setLoginForm,
    submitMessage,
    onSubmit: (e) =>
      postToSockets("login", loginForm, e).then(loginSuccess, displayErr),
    options: emailAndPwOptions,
  };

  const createFormProps: FormProps<CreateForm> = {
    actionButton: "Create Account",
    form: createForm,
    setForm: setCreateForm,
    submitMessage,
    onSubmit: (e) =>
      postToSockets("createUser", createForm, e).then(loginSuccess, displayErr),
    options: {
      ...emailAndPwOptions,
      alias: {
        validator: (val) => (!val?.length ? "Please Choose an Alias" : ""),
      },
    },
  };

  return (
    <section className={styles.loginSection}>
      {loginOrCreate === "login" ? (
        <MyForm {...loginFormProps}>
          <button
            className={styles.alt}
            onClick={(e) => {
              e.preventDefault();
              setSubmitMessage(undefined);
              setLoginOrCreate("create");
            }}>
            Create Account
          </button>
        </MyForm>
      ) : (
        <MyForm {...createFormProps}>
          <button
            className={styles.alt}
            onClick={(e) => {
              e.preventDefault();
              setSubmitMessage(undefined);
              setLoginOrCreate("login");
            }}>
            Already Have Account
          </button>
        </MyForm>
      )}
      <div className={styles["fancy-logins"]}>
        <GoogleButton loginSuccess={loginSuccess} displayErr={displayErr} />
      </div>
    </section>
  );
}
