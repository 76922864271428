import React from "react";
import { easyStyles } from "../../_Utilities/utils";
import styles from "./Snackbar.module.scss";

export interface SnackbarMsg {
  type: "error" | "success";
  message: string;
}

interface Props {
  message: SnackbarMsg;
}
export default function Snackbar({ message }: Props) {
  return (
    <div className={easyStyles(styles, "snackbar", message.type)}>
      {message.message}
    </div>
  );
}
