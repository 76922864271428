import React, { useState } from "react";
import CrewIcon from "../../_Utilities/Icons/CrewIcon";
import { condContent, easyStyles } from "../../_Utilities/utils";
import InviteOverlay from "./InviteOverlay/InviteOverlay";
import CancelIcon from "../../_Utilities/Icons/CancelIcon";
import EraserIcon from "../../_Utilities/Icons/EraserIcon";
import styles from "./Toolbar.module.scss";
import { LoggedInProps } from "../VirtualWhiteboard";

interface Props extends LoggedInProps {
  users: { id: string; name: string }[];
  myId: string;
  tool: Tool;
  setTool: (t: Tool) => void;
  clearCanvas: () => void;
}

const COLORS_LU = {
  Black: "#000",
  Blue: "#00d",
  Green: "#0d0",
  Red: "#d00",
};

type Color = keyof typeof COLORS_LU;
export type Tool = Color | "Eraser";
export const ERASER_SIZE = 40;

const TOOLS: Tool[] = (Object.keys(COLORS_LU) as Tool[]).concat("Eraser");

export default function Toolbar({
  users,
  myId,
  tool,
  setTool,
  clearCanvas,
  user,
  room,
}: Props) {
  const [invite, setInvite] = useState(false);
  const drawTool = (t: Tool, i: number) => {
    const selected = tool === t;
    return (
      <button
        key={i}
        onClick={() => setTool(t)}
        className={easyStyles(styles, { selected })}>
        {t === "Eraser" ? (
          <EraserIcon className={styles["tool-indicator"]} />
        ) : (
          <div
            className={easyStyles(styles, "tool-indicator", "color")}
            style={{ backgroundColor: COLORS_LU[t] }}></div>
        )}{" "}
        <span>{t}</span>
      </button>
    );
  };

  const showUser = (u: { name: string; id: string }, i: number) => (
    <div className={styles["user-name"]} key={i}>
      {u.name}
      {u.id === myId ? " (me)" : ""}
    </div>
  );

  const inviteButton = (
    <button
      className={styles["invite"]}
      onClick={() => setInvite(true)}
      onKeyUp={(e) => (e.key === "Escape" ? setInvite(false) : "")}>
      Invite
    </button>
  );

  return (
    <div className={styles["Toolbar"]}>
      <div className={styles["tool-list"]}>
        {TOOLS.map(drawTool)}
        <button className={styles["tool"]} onClick={() => clearCanvas()}>
          <CancelIcon className={styles["tool-indicator"]} />
          <span>Clear</span>
        </button>
      </div>
      <div className={styles["user-list"]}>
        <h3>Users In Room</h3>
        {users.map(showUser)}
        {inviteButton}
      </div>
      <div className={styles["user-list-little"]} tabIndex={-1}>
        <CrewIcon></CrewIcon>
        <div className={styles["user-count"]}>{users.length}</div>
        <div className={styles["full-list"]}>
          <h3>Users In Room:</h3>
          {users.map(showUser)}
          {inviteButton}
        </div>
      </div>
      {condContent(
        invite,
        <InviteOverlay close={() => setInvite(false)} {...{ user, room }} />
      )}
    </div>
  );
}
