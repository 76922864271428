import styles from "./Spinner.module.scss";

export default function Spinner({ className }: { className?: string }) {
  return (
    <svg
      className={`${className || ""} ${styles.spinner}`}
      xmlns="http://www.w3.org/2000/svg"
      height="50px"
      width="50px"
      viewBox="0 0 50 50">
      <circle
        className={styles.path}
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"></circle>
    </svg>
  );
}
