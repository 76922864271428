import { useEffect, useState } from "react";
import { ReactSetter } from "../../_Utilities/ReactAliases";
import { httpProm, API_ENDPOINT } from "../../_Utilities/utils";
import { TOKEN_STORE, UserI, WhiteboardPreview } from "../../data-types";
import AccountManagement from "../../AccountManagement/AccountManagement";
import BoardPreview from "./BoardPreview";
import styles from "./SelectBoards.module.scss";
import { useNavigate } from "react-router-dom";
import Spinner from "../../_Utilities/Spinner/Spinner";
import { SnackbarMsg } from "../Snackbar/Snackbar";

interface Props {
  user: UserI;
  setUser: ReactSetter<UserI | null>;
  notifyUser: (msg: SnackbarMsg) => void;
}

export default function SelectBoards({ user, setUser, notifyUser }: Props) {
  const navigate = useNavigate();
  const [boards, setBoards] = useState<WhiteboardPreview[]>([]);
  const [createSpinner, setCreateSpinner] = useState(false);

  // Will be called when boards are deleted
  function refreshBoards() {
    const token = localStorage.getItem(TOKEN_STORE) || "";
    httpProm(`${API_ENDPOINT}get-whiteboards`, { query: { token } }).then(
      (fetchedBoards) => {
        if (!fetchedBoards) return console.warn("No data received...");
        const parsed = JSON.parse(fetchedBoards.text);
        console.log(parsed);
        if (parsed) setBoards(parsed);
      },
      (err) => {
        console.log("Error getting whiteboards");
        console.log(err);
        if (err.status === 401) return setUser(null);
        else throw new Error(err);
      }
    );
  }

  // Do it once on startup
  useEffect(refreshBoards, [setUser]);

  const goToRoom = (roomId: string) => navigate(`?room=${roomId}`);

  function createBoard() {
    setCreateSpinner(true);
    const token = localStorage.getItem(TOKEN_STORE) || "";
    httpProm(`${API_ENDPOINT}create-board`, { query: { token } }).then(
      // It just responds with the new board ID
      (suc) => {
        setCreateSpinner(false);
        notifyUser({
          type: "success",
          message: "Board Created!",
        });
        goToRoom(suc.text);
      },
      (err) => {
        notifyUser({
          type: "error",
          message: `Error Creating board... ${err.text}`,
        });
        setCreateSpinner(false);
      }
    );
  }

  return (
    <>
      <div className={styles["previews"]}>
        <header>
          <div className={styles.titles}>
            <h1>Virtual Whiteboards </h1>
            <h2>Select a Board</h2>
          </div>
          <AccountManagement className={styles.logout} {...{ user, setUser }} />
        </header>
        {!boards ? (
          <div className={styles.loading}>Loading...</div>
        ) : (
          <>
            <button className={styles["preview-box"]} onClick={createBoard}>
              <h3>Create New</h3>
              <div className={styles["create-new"]}>
                {createSpinner ? <Spinner /> : "+"}
              </div>
            </button>
            {boards.map((board, i) => (
              <BoardPreview
                key={i}
                {...{ board, goToRoom, refreshBoards, user }}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
}
