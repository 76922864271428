import React from "react";
import { KeyHandle, MouseHandle, SVG } from "../ReactAliases";
import styles from "./IconStyles.module.scss";

// This lets me style the icons like they were normal elements
export interface PublicProps {
  onClick?: MouseHandle<SVG>;
  onKeyUp?: KeyHandle<SVG>;
  className?: string;
}
// Default svg Attributes
export interface IconInternalProps extends PublicProps {
  xmlns: string;
  height: string;
  viewBox: string;
  width: string;
  fill: string;
}

export function MakeSVG({
  className,
  children,
  onClick,
  onKeyUp,
  ...otherProps
}: React.PropsWithChildren<IconInternalProps>) {
  return (
    <svg
      tabIndex={onClick || onKeyUp ? 0 : undefined}
      onKeyUp={onKeyUp}
      onClick={onClick}
      className={`${styles.Icon} ${className || ""}`}
      // All the rest
      {...otherProps}>
      {children}
    </svg>
  );
}
