import { Stage as StageType } from "konva/lib/Stage";
import { useEffect, useRef, useState } from "react";
import { Layer, Stage } from "react-konva";
import { forceUpdateResizeEffect } from "../../_Utilities/utils";
import { KMouse, Point } from "../../data-types";
import { ERASER_SIZE } from "../Toolbar/Toolbar";
import { VirtualWhiteboard, WhiteboardState } from "../VirtualWhiteboard";
import ConciseLine from "./KonvaLine";
import styles from "./KonvaWrapper.module.scss";

////////////////////////////////////////////////////////////////////////////////////////
// This Handles turning the line data into a konva stage, and it manages the details of
// mouse event handling.
// Also Handling some resizing display stuff

interface Props extends WhiteboardState {
  startLine: (...args: Parameters<VirtualWhiteboard["startLine"]>) => void;
  continueLine: (
    ...args: Parameters<VirtualWhiteboard["continueLine"]>
  ) => void;
  endLine: (...args: Parameters<VirtualWhiteboard["endLine"]>) => void;
}

export default function KonvaWrapper({
  whiteBoard,
  drawTracker,
  startLine,
  tool,
  continueLine,
  endLine,
}: Props) {
  const [mousePos, setMousePos] = useState<Point>({ x: 0, y: 0 });
  // A way to track when the mouse is in the field, so I don't get funky behavior when the mouse leaves
  const [mouseIn, setMouseIn] = useState(false);
  const [x, forceUpdate] = useState(0);
  const canvasCol = useRef<HTMLDivElement>(null);
  const stageRef = useRef<StageType>(null);

  /* When I'm testing different screen sizes, this causes it to automatically update*/
  useEffect(() => forceUpdateResizeEffect(() => forceUpdate((x) => x + 1)), []);

  const mouseDn = (e: KMouse) => {
    const pos = e.target.getStage()?.getPointerPosition();
    if (!pos) return;
    startLine(pos, "me", "me");
  };

  const mouseMv = (e: KMouse) => {
    const pos = e.target.getStage()?.getPointerPosition();
    if (!pos) return;
    setMouseIn(true);
    setMousePos(pos);
    if (!drawTracker?.me.drawing) return;
    continueLine(pos, "me");
  };

  const mouseUp = (e: KMouse) => endLine("me");

  const mouseLeave = (e: KMouse) => {
    if (drawTracker?.me.drawing) endLine("me");
    setMouseIn(false);
  };

  const visibleCanvasHeight = () => {
    if (!canvasCol.current) return 600;
    const rect = canvasCol.current.getBoundingClientRect();
    return window.innerHeight - rect.top - 80;
  };

  // Scales with both height and width?
  const scale = Math.min(
    1,
    (window.innerWidth - 30) / 800,
    visibleCanvasHeight() / 600
  );
  const width = 800; // Math.min(800, window.innerWidth - 50);
  const height = 600; // Math.min(600, (width * 6) / 8);

  // mapped over each draw tracker to give an icon indicating another user drawing
  const makeUserIndicator = (id: string, i: number) => {
    const drawFig = drawTracker[id];
    const index = drawFig.index;
    if (
      !drawFig.drawing ||
      typeof index === "undefined" ||
      drawFig.name === "me"
    )
      return "";
    const line = (whiteBoard?.lines || [])[index];
    if (!line.points) return "";
    const lastPoints = line.points.slice(-2);
    return (
      <div
        key={i}
        className={styles["user-indicator"]}
        style={{ top: lastPoints[1] - 30, left: lastPoints[0] }}>
        {drawFig.name}
      </div>
    );
  };

  const eraserCursor = () => {
    if (tool !== "Eraser" || !mouseIn) return "";
    return (
      <div
        className={styles["eraser-cursor"]}
        style={{
          height: ERASER_SIZE,
          width: ERASER_SIZE,
          top: mousePos.y - ERASER_SIZE / 2,
          left: mousePos.x - ERASER_SIZE / 2,
        }}></div>
    );
  };

  const test = () => {
    console.log(visibleCanvasHeight());
    // console.log()
  };

  return !whiteBoard ? (
    <div className={styles["loading"]}>Loading...</div>
  ) : (
    // Kinda messy that I'm using two wrapper divs, but it works and I don't want to refactor it
    // for no reason
    <div className={styles["canvas-wrapper"]} ref={canvasCol} onClick={test}>
      <div
        className={styles["canvas-resizer"]}
        style={{ height, width, transform: `scale(${scale})` }}>
        {eraserCursor()}
        {Object.keys(drawTracker).map(makeUserIndicator)}
        <Stage
          ref={stageRef}
          height={height}
          width={width}
          onMouseDown={mouseDn}
          onMousemove={mouseMv}
          onMouseup={mouseUp}
          onTouchStart={mouseDn}
          onTouchMove={(e) => {
            // Prevent the default action of scrolling on mobile
            e.evt.preventDefault();
            mouseMv(e);
          }}
          onTouchEnd={mouseUp}
          onMouseLeave={mouseLeave}
          className={styles["canvas-stage"]}>
          <Layer>
            {(whiteBoard?.lines || []).map((line, i) => (
              <ConciseLine key={i} line={line} />
            ))}
          </Layer>
        </Stage>
      </div>
    </div>
  );
}
