import React, { useEffect, useMemo, useState } from "react";
import { easyStyles, httpProm, API_ENDPOINT } from "../_Utilities/utils";
import { TOKEN_STORE, UserI } from "../data-types";
import styles from "./Lobby.module.scss";
import VirtualWhiteboard from "../Whiteboard/VirtualWhiteboard";
import LoginForm from "./LoginForm/LoginForm";
import SelectBoards from "./SelectBoards/SelectBoards";
import { useLocation } from "react-router";
import Snackbar, { SnackbarMsg } from "./Snackbar/Snackbar";

export function useRoom() {
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  return query.get("room");
}

export default function Lobby() {
  const room = useRoom();
  // If I have a token, then I'm logged in, if I don't them I'm not
  // if one of my children gets a 401 response from the API, then I will set my logged in value
  // to false
  const [user, setUser] = useState<UserI | null>(null);
  const [snackBarMsg, setSnackbarMsg] = useState<SnackbarMsg | null>(null);

  function notifyUser(message: SnackbarMsg) {
    setSnackbarMsg(message);
    setTimeout(() => setSnackbarMsg(null), 5000);
  }

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_STORE);
    if (!token) return;
    httpProm(`${API_ENDPOINT}verify-token`, { query: { token } }).then(
      (suc) => {
        try {
          const user = JSON.parse(suc.text);
          console.log(user);
          setUser(user);
        } catch (err) {
          console.log("Having trouble reaching the server...");
          console.warn(err);
        }
      },
      (err) => {
        // I'm keeping these logs until I'm 100% sure everything works
        console.log("Server didn't like my saved token");
        console.log(err);
      }
    );
  }, []);

  return (
    <>
      {!user ? (
        <div className={easyStyles(styles, "login", "Lobby")}>
          <h1>Virtual Whiteboards</h1>
          <LoginForm setUser={setUser} />
        </div>
      ) : !room ? (
        <div className={easyStyles(styles, "board-picker", "Lobby")}>
          <SelectBoards {...{ user, setUser, notifyUser }} />
        </div>
      ) : (
        <VirtualWhiteboard {...{ user, room, notifyUser }} />
      )}
      {snackBarMsg && <Snackbar message={snackBarMsg} />}
    </>
  );
}
