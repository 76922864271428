import { LineConfig } from "konva/lib/shapes/Line";
import { Line } from "react-konva";

// This is a bunch of boilerplate that gets reused (and it was really annoying de-bugging some
// line display stuff in two different places, so this will eliminate that)
export default function ConciseLine({ line }: { line: LineConfig }) {
  return (
    <Line
      points={line.points}
      stroke={line.stroke}
      strokeWidth={line.strokeWidth}
      bezier={true}
      lineCap="round"
      lineJoin="round"
      globalCompositeOperation={line.globalCompositeOperation || "source-over"}
    />
  );
}
