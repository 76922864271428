import React, { useState } from "react";
import styles from "./MyForm.module.scss";
import { mapValues, upperFirst } from "lodash";
import { easyStyles, typedKeys } from "../utils";
import { Errors, FieldOptions, FormProps } from "./FormTypes";
import PwVisible from "../Icons/PwVisible";
import PwHidden from "../Icons/PwHidden";

export default function MyForm<F extends { [key: string]: string }>({
  form,
  setForm,
  onSubmit,
  className,
  options,
  actionButton,
  children,
  submitMessage,
}: React.PropsWithChildren<FormProps<F>>) {
  const [errors, setErrors] = useState<Errors<F>>({});
  const setError = (field: keyof F, error: string) => {
    setErrors((oldErrors) => ({ ...oldErrors, [field]: error }));
  };
  const checkError = (field: keyof F) => {
    // if there are no validators the form is always good
    if (!options) return false;
    // Some fields might not need to be validated
    const checker = options[field as string]?.validator;
    if (!checker) return false;
    const error = checker(form[field]);
    if (error) setError(field, error);
    return error;
  };

  return (
    <form
      onSubmit={(e) => {
        console.log("Doing submit");
        e.preventDefault();

        const allGood = typedKeys(form)
          .map(checkError)
          .every((x) => !x);
        if (allGood) onSubmit(e);
      }}
      className={`${styles.myForm} ${className}`}>
      {typedKeys(form).map((field, i) => {
        const fieldOptions = options && options[field as string];
        // Mask will alter an input (e.g. restrict only digits to a phone number input)
        // this will cause it to default to no effect if it's not given
        const mask = fieldOptions?.fieldMask || ((x) => x);
        return (
          <FormField<F>
            key={i}
            field={field as any}
            value={form[field] as any}
            setField={(event) =>
              setForm((oldForm) => ({ ...oldForm, [field]: mask(event) }))
            }
            options={fieldOptions}
            error={errors && errors[field]}
            setError={(e) => setError(field, e)}
          />
        );
      })}
      <div
        className={easyStyles(
          styles,
          "submitMessageHandler",
          submitMessage?.type
        )}
        data-error={submitMessage?.message}>
        <button type="submit">{actionButton}</button>
      </div>
      {children}
    </form>
  );
}

interface FieldProps<F extends { [key: string]: string }> {
  field: keyof F;
  value: string;
  setField: (newVal: F[keyof F]) => void;
  options?: FieldOptions;
  error?: string;
  setError?: (x: string) => void;
}

function FormField<F extends { [key: string]: string }>({
  field,
  value,
  setField,
  options,
  error,
  setError,
}: FieldProps<F>) {
  const [pwvisible, setPwVisible] = useState(false);
  if (typeof field != "string") return <span>Invalid Form Field</span>;
  return (
    <div
      className={easyStyles(styles, "fieldRow", { error: !!error })}
      data-error={error}>
      <label htmlFor={field}>{upperFirst(field)}</label>
      <input
        id={field}
        type={
          options?.type === "password" && pwvisible ? "text" : options?.type
        }
        value={value as any}
        placeholder={options?.placeholder}
        onChange={(e) => {
          setError && setError("");
          // I specify that the value type of the form is a string (or at least I'm trying to..)
          // but I guess I have to reiterate it here
          setField(e.target.value as F[keyof F]);
        }}
      />
      {options?.type === "password" && (
        // When this is a button it messes with the form submission
        // I guess pressing enter on a form field will trigger the on-click
        // of the next button element. So this can't be a button
        <div
          title={pwvisible ? "hide password" : "show password"}
          className={styles["pw-toggle"]}
          onClick={(e) => {
            e.preventDefault();
            setPwVisible((h) => !h);
          }}>
          {pwvisible ? <PwHidden /> : <PwVisible />}
        </div>
      )}
    </div>
  );
}
