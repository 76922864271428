import React, { useEffect, useRef, useState } from "react";
import { Layer, Stage } from "react-konva";
import {
  API_ENDPOINT,
  getContentWidth,
  httpProm,
  isAdmin,
} from "../../_Utilities/utils";
import { TOKEN_STORE, UserI, WhiteboardPreview } from "../../data-types";
import ConciseLine from "../../Whiteboard/KonvaWrapper/KonvaLine";
import styles from "./SelectBoards.module.scss";
import CloseIcon from "../../_Utilities/Icons/CloseIcon";

interface Props {
  user: UserI;
  board: WhiteboardPreview;
  goToRoom: (x: string) => void;
  refreshBoards: () => void;
}
export default function BoardPreview({
  board,
  goToRoom,
  refreshBoards,
  user,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [deleteState, setDeleteState] = useState("");
  const [boardSizing, setBoardSizing] = useState({
    height: 0,
    width: 0,
    scale: 0,
  });

  const baseHeight = 600;
  const baseWidth = 800;
  // The board size is figured out based on the width of the containing button
  // so I can style the button normally
  useEffect(() => {
    const contentWidth = getContentWidth(containerRef.current) || 0;
    // only get as big as 100% of original size
    const scale = Math.min(1, contentWidth / baseWidth);

    const height = baseHeight * scale;
    const width = baseWidth * scale;
    setBoardSizing({ scale, height, width });
  }, [containerRef]);

  function deleteBoard() {
    setDeleteState("Deleting...");
    const token = localStorage.getItem(TOKEN_STORE) as string;
    httpProm(`${API_ENDPOINT}delete-board`, {
      query: { token },
      method: "POST",
      body: { board: board._id },
    }).then(
      (suc) => {
        setDeleteState("");
        refreshBoards();
      },
      (err) => setDeleteState(err.text)
    );
  }

  const displayBoard = () => {
    return (
      <div
        className={styles["stage-container"]}
        style={{
          height: boardSizing.height,
          width: boardSizing.width,
          overflow: "hidden",
        }}>
        <Stage
          height={baseHeight}
          width={baseWidth}
          className={styles["preview-stage"]}
          style={{
            transform: `scale(${boardSizing.scale})`,
            transformOrigin: "top left",
            height: baseHeight,
            width: baseWidth,
          }}>
          <Layer>
            {board.lines.map((line, i) => (
              <ConciseLine key={i} line={line} />
            ))}
          </Layer>
        </Stage>
      </div>
    );
  };

  return (
    <>
      <div ref={containerRef} className={styles["preview-box"]}>
        {/* I was having some issues on the phone with the click event not working when you poke the 
         stage it's hard to test, so I'm kinda brute forcing it by just covering everything with the button that will catch the event */}

        <button
          onClick={() => setDeleteState("prompt")}
          className={styles.delete}>
          <CloseIcon />
        </button>

        <button
          className={styles["click-cover"]}
          onClick={(e) => goToRoom(board._id)}></button>
        <h3>{board.name}</h3>
        <div className={styles["users"]}>
          Active Users: {board.userData.length}
        </div>
        {isAdmin(user) && (
          <div className={styles["users"]}>Created By: {board.createdBy}</div>
        )}

        {displayBoard()}
      </div>
      {deleteState && (
        <div
          className={styles["delete-modal"]}
          onClick={() => setDeleteState("")}>
          <div
            className={styles["delete-body"]}
            onClick={(e) => e.stopPropagation()}>
            <h2>Do You Want to Delete This Board?</h2>
            {displayBoard()}
            {deleteState === "prompt" ? (
              <div className={styles["button-row"]}>
                <button onClick={deleteBoard}>Yes</button>
                <button
                  onClick={() => setDeleteState("")}
                  className={styles["alt"]}>
                  No
                </button>
              </div>
            ) : (
              <div>{deleteState}</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
