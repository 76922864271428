import { MakeSVG, PublicProps } from "./_IconMain";

export default function Icon(props: PublicProps) {
  return (
    <MakeSVG
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="#000000">
      <path d="M13.5 1L13.9242 0.575736C13.8117 0.463214 13.6591 0.4 13.4999 0.4C13.3408 0.4 13.1882 0.463214 13.0757 0.575737L13.5 1ZM21 8.5L21.4336 8.9147C21.659 8.67899 21.6548 8.30636 21.4242 8.07574L21 8.5ZM1.99999 12.5L1.57572 12.0757L1.57571 12.0758L1.99999 12.5ZM5.99999 20L5.57572 20.4243C5.68825 20.5368 5.84086 20.6 5.99999 20.6V20ZM1.99995 16L1.50071 16.3328L1.53367 16.3823L1.57569 16.4243L1.99995 16ZM4.49998 10L4.07571 9.57574L4.49998 10ZM10.5757 16.9243C10.81 17.1586 11.1899 17.1586 11.4243 16.9243C11.6586 16.6899 11.6586 16.3101 11.4242 16.0757L10.5757 16.9243ZM21 20.6C21.3313 20.6 21.6 20.3314 21.6 20C21.6 19.6686 21.3313 19.4 21 19.4V20.6ZM3.07574 22.0757C2.84142 22.3101 2.84142 22.6899 3.07574 22.9243C3.31005 23.1586 3.68995 23.1586 3.92426 22.9243L3.07574 22.0757ZM4.92426 21.9243C5.15858 21.6899 5.15858 21.3101 4.92426 21.0757C4.68995 20.8414 4.31005 20.8414 4.07574 21.0757L4.92426 21.9243ZM1 19.4C0.668629 19.4 0.4 19.6686 0.4 20C0.4 20.3314 0.668629 20.6 1 20.6V19.4ZM2.5 20.6C2.83137 20.6 3.1 20.3314 3.1 20C3.1 19.6686 2.83137 19.4 2.5 19.4V20.6ZM13.0757 1.42426L20.5757 8.92426L21.4242 8.07574L13.9242 0.575736L13.0757 1.42426ZM20.5663 8.0853L9.56805 19.5853L10.4353 20.4147L21.4336 8.9147L20.5663 8.0853ZM10.0017 19.4H5.99999V20.6H10.0017V19.4ZM6.42425 19.5757L2.42421 15.5757L1.57569 16.4243L5.57572 20.4243L6.42425 19.5757ZM2.49918 15.6672C1.65843 14.406 2.12579 13.2227 2.42426 12.9242L1.57571 12.0758C0.874248 12.7773 0.341536 14.594 1.50071 16.3328L2.49918 15.6672ZM2.42425 12.9243L4.92424 10.4243L4.07571 9.57574L1.57572 12.0757L2.42425 12.9243ZM4.92424 10.4243L13.9242 1.42426L13.0757 0.575737L4.07571 9.57574L4.92424 10.4243ZM4.07571 10.4243L10.5757 16.9243L11.4242 16.0757L4.92424 9.57574L4.07571 10.4243ZM10.0017 20.6H21V19.4H10.0017V20.6ZM3.92426 22.9243L4.92426 21.9243L4.07574 21.0757L3.07574 22.0757L3.92426 22.9243ZM1 20.6H2.5V19.4H1V20.6Z" />
    </MakeSVG>
  );
}
