import React, { useRef, useState } from "react";
import CloseIcon from "../../../_Utilities/Icons/CloseIcon";
import MyForm from "../../../_Utilities/MyForm/MyForm";
import {
  httpProm,
  API_ENDPOINT,
  validateEmail,
} from "../../../_Utilities/utils";
import { FormProps, SubmitMessage } from "../../../_Utilities/MyForm/FormTypes";
import { LoggedInProps } from "../../VirtualWhiteboard";
import styles from "./InviteOverlay.module.scss";

interface Props extends LoggedInProps {
  close: () => void;
}

type ChangeHandler = React.ChangeEventHandler<HTMLInputElement>;

const condStyle = (cond: any, val: string) => (cond ? styles[val] : "");

export default function InviteOverlay({ user, room, close }: Props) {
  const [phoneForm, setPhoneForm] = useState({ phone: "" });
  const [phoneSubmitMsg, setPhoneSubmitMsg] = useState<
    SubmitMessage | undefined
  >(undefined);
  const [phoneNotify, setPhoneNotify] = useState("");
  const [emailNotify, setEmailNotify] = useState("");
  const email = useRef("");

  // Site your source! https://stackoverflow.com/questions/17651207/mask-us-phone-number-string-with-javascript
  const numberChange = (number: string) => {
    setPhoneNotify("");
    var x = number.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (x) number = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? "-" + x[3] : ""}`;
    return number.replace(/\D/g, "");
  };

  const emailChange: ChangeHandler = (e) => {
    setEmailNotify("");
    email.current = e.target.value;
  };

  const validateNumber = (phone: string) => {
    // There are 10 characters, there are 10 digits, and the first character insn't 1 (no areacodes start with 1)
    return phone.length === 10 &&
      phone.match(/\d/g)?.length === 10 &&
      phone[0] !== "1"
      ? ""
      : "Invalid Phone";
  };

  const getInviteBody = (to: string) => {
    // cooercing type because I know that they're all strings
    return { to, room, user: decodeURIComponent(user.alias) };
  };

  const makeHandlers = (setNot: (x: SubmitMessage | undefined) => void) => {
    const timeoutReset = () => setTimeout(() => setNot(undefined), 1800);
    return [
      (suc: any) => {
        console.log(suc);
        setNot({ type: "success", message: "Successfully Sent" });
        timeoutReset();
      },
      (err: any) => {
        console.error(err);
        setNot({ type: "error", message: "Error Sending..." });
        timeoutReset();
      },
    ];
  };

  const sendEmail = () => {
    if (!validateEmail(email.current)) return setEmailNotify("Invalid Email");
    setEmailNotify("Sending...");
    const to = email.current;
    const query = getInviteBody(to);
    // httpProm(API_ENDPOINT + "email-wb-invite", { query }).then(suc, err);
  };

  const noBubble: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  const phoneFormFig: FormProps<{ phone: string }> = {
    form: phoneForm,
    setForm: setPhoneForm,
    onSubmit: () => {
      const to = "+1" + phoneForm.phone;
      const query = getInviteBody(to);
      const [suc, err] = makeHandlers(setPhoneSubmitMsg);
      httpProm(API_ENDPOINT + "text-wb-invite", { query }).then(suc, err);
    },
    // text of submit button
    actionButton: "Send",
    className: styles['invite-form'],
    options: {
      phone: {
        validator: validateNumber,
        fieldMask: numberChange,
        placeholder: "(xxx) xxx-xxxx",
      },
    },
    submitMessage: phoneSubmitMsg,
  };

  const inviteForm = (
    method: string,
    send: () => void,
    onChange: ChangeHandler,
    notify: string,
    placeholder: string
  ) => (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        send();
      }}
      className={[
        styles["invite"],
        condStyle(notify, "notify"),
        condStyle(notify.includes("Success"), "suc"),
        condStyle(
          ["Invalid", "Error"].some((x) => notify.includes(x)),
          "err"
        ),
      ].join(" ")}>
      <label>Invite By {method}</label>
      <div data-notify={notify}>
        <input type="text" placeholder={placeholder} onChange={onChange} />
        <button className={styles["send"] + " btn-clr"} type="submit">
          Send
        </button>
      </div>
    </form>
  );

  return (
    <div
      className={styles["InviteOverlay"]}
      onClick={close}
      onKeyUp={(e) => (e.key === "Escape" ? close() : "")}>
      <div className={styles["overlay-content"]} onClick={noBubble}>
        <button className={styles["close"]} onClick={close}>
          <CloseIcon className={styles["close-icon"]} />
        </button>
        <h2>Invite Someone</h2>
        <div>Invite by Phone</div>
        {<MyForm {...phoneFormFig} />}
        {inviteForm(
          "Email",
          sendEmail,
          emailChange,
          emailNotify,
          "user@example.com"
        )}
        <div className={styles["link-section"]}>
          <div>Invite Link:</div>
          <div className={styles["invite-link"]}>
            https://connorjamesallen.com/virtual-whiteboard?room=
            {room}
          </div>
        </div>
      </div>
    </div>
  );
}
