import { flatten } from "lodash";
import React, { RefObject } from "react";
import { UserI } from "../data-types";
import { El, FocusHandler } from "./ReactAliases";

// I don't do any security checks with this, but I display information differently
// to myself when I'm logged in. (all the endpoints I'd http to are protected
// server side)
export function isAdmin(user: UserI) {
  return (
    user.email === "cja.callen@gmail.com" && user.linkedAccount === "google"
  );
}

export const API_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? "https://sockets.connorjamesallen.com/"
    : "http://localhost:8001/";

export function typedKeys<T>(obj: T): (keyof T)[] {
  // Annoyingly this isn't default behavior of object.keys so I have this when I want/need to force it.
  return Object.keys(obj) as (keyof T)[];
}

// https://stackoverflow.com/questions/48011353/how-to-unwrap-type-of-a-promise
// Typescript magic for unwrapping the resolve type of a promise
export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

// Alias for long name
export const uriSafe = (x: Parameters<typeof encodeURIComponent>[0]) =>
  encodeURIComponent(x);

export interface HTTP_Res {
  text: string;
  status: number;
}

export function httpProm(
  url: string,
  options?: {
    method?: "GET" | "POST" | "PUT";
    query?: { [key: string]: string };
    body?: any;
  }
): Promise<HTTP_Res> {
  const http = new XMLHttpRequest();
  const method = options?.method || "GET";
  const query = options?.query;
  const builtQuery = !query
    ? ""
    : "?" +
      Object.keys(query)
        .map((key) => `${uriSafe(key)}=${uriSafe(query[key])}`)
        .join("&");

  return new Promise((res, rej) => {
    try {
      http.open(method, url + builtQuery);
      // This seems like the simplest option
      http.setRequestHeader("Content-Type", "application/json");
      // IGNORED IF THE REQUEST IS GET
      http.send(JSON.stringify(options?.body));
      http.onreadystatechange = () => {
        const { readyState, status, responseText: text } = http;
        if (readyState === 4)
          status < 400 ? res({ text, status }) : rej({ text, status });
      };
    } catch (err) {
      console.log("error in http prom");
      rej(err);
    }
  });
}

export function condContent(
  test: any,
  conditionalContent: JSX.Element,
  alt: JSX.Element | "" = ""
) {
  // This is just a teeeeeeny bit cleaner than the normal way to do conditional content in jsx
  // {testVal ? (
  //   conditional content
  // ) : (
  //   ''
  // )}
  return test ? conditionalContent : alt;
}

export function onlyUnique<T>(v: T, i: number, arr: T[]): boolean {
  return arr.indexOf(v) === i;
}

// Utility function for adding multiple css classes from a module
// conditional styles can be added like this: { opened }
export function easyStyles(
  styles: { [key: string]: string },
  ...classes: (string | { [key: string]: boolean } | undefined | null)[]
) {
  return (
    classes
      // filter out undefined (or other falsey stuff)
      .filter((c) => c)
      .map((c) => {
        // If it's a normal class name, get it off the styles
        if (typeof c === "string") return styles[c];
        // Otherwise it's a conditional class mapped to a boolean
        return (
          c &&
          Object.keys(c)
            // Filter out for only keys mapped to a boolean
            .filter((key) => c[key])
            // get the value off the styles object and return a joined list
            .map((key) => styles[key])
            .join(" ")
        );
      })
      .join(" ")
  );
}

export function getContentWidth(el: HTMLElement | null) {
  if (!el) return;
  const style = getComputedStyle(el);
  return (
    el.clientWidth -
    parseFloat(style.paddingLeft) -
    parseFloat(style.paddingRight)
  );
}

// I use this in a couple places to get components to re-render as I change screen size
// To review how event listener options work. This is a good way to do disconnection
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
export function forceUpdateResizeEffect(forceUpdate: () => void) {
  const disconnector = new AbortController();
  const { signal } = disconnector;
  window.addEventListener("resize", forceUpdate, { signal } as any);
  return () => disconnector.abort();
}

export const realBlurCapture = (
  onBlur: FocusHandler<HTMLElement>,
  // Does it count as a blur if the focus went to the element itself?
  includeMe = true
) => {
  const ret: FocusHandler<HTMLElement> = (event) => {
    const { relatedTarget: focusTo, currentTarget: isWithin } = event;
    function checkFocusBlur(focusToNode: HTMLElement | null): boolean {
      // If the focus was sent to a blank space, then I've
      // definitely blurred (or I've worked all the way up the tree)
      if (!focusToNode) return true;
      // If I refocus to the parent element (based on the includeMe argument) I will either count this
      // as a blur within or not... I accomplish that by immediately comparing the parent element
      const toCompare = includeMe ? focusToNode : focusToNode.parentElement;
      // If the compare value is equal to the component I'm checking for blurWithin
      // then I haven't left that component
      if (toCompare === isWithin) return false;
      // If neither of the above are true, work up the parent tree. and try again
      return checkFocusBlur(focusToNode.parentElement);
    }
    // React is being bitchy about the typing, but it works
    if (checkFocusBlur(focusTo as HTMLElement)) onBlur(event);
  };
  return ret;
};

// Should allow me to navigate pp and down through focusable elements with arrow keys
// Just slap it into the key handler
export function arrowNav<T>(e: React.KeyboardEvent<T>, ref: RefObject<El>) {
  // So I can put this on a big container and navigate through all nested focusable elements...
  // I want to create the list of nested focusable elements as a flat list
  function getFlatFocusableChildren(el: El | null): El[] {
    if (!el) return [] as El[];

    function flatChildren(e: El): El[] {
      // Typescript is stupid and wrong about HTML types...
      const children: HTMLElement[] = [...(e?.children as any)];
      // This will actually terminate when the el has no children (the list is 0 length)
      return [e, ...flatten(children.map((c) => flatChildren(c)))];
    }
    // All Children that are focusable (if I want to have a negative tabindex while still being)
    // arrow navigable, I can do tab-index -2
    return flatChildren(el).filter((e) => e.tabIndex !== -1);
  }

  // Only operate on up and down keys
  // and if there are real children to navigate through
  if (!["ArrowUp", "ArrowDown"].includes(e.key)) return;
  e.preventDefault();
  const children = getFlatFocusableChildren(ref?.current);
  // not really meaningful if there are no children
  if (!children.length) return;
  const focusedChild = children.find?.(
    (el: any) => el === document.activeElement
  );
  // If I can't figure out which of my children are focused... give up
  if (!focusedChild) return;
  const index = children.indexOf(focusedChild);
  const direction = e.key === "ArrowUp" ? -1 : 1;
  children[index + direction]?.focus?.();
}

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function modalClickHandle(e: React.MouseEvent<any>) {
  e.stopPropagation();
}
