import React, { Fragment } from "react";
import { io, Socket } from "socket.io-client";

import {
  DEFAULT_LINE,
  DrawState,
  DrawTracker,
  LISTEN_EVENTS,
  Point,
  WhiteboardI,
  continueLine as continueLineOnBoard,
  addLine as addLineOnBoard,
  DrawListens,
  DrawEmits,
  LineFig,
  TOKEN_STORE,
  UserI,
} from "../data-types";
import styles from "./VirtualWhiteboard.module.scss";
import KonvaWrapper from "./KonvaWrapper/KonvaWrapper";
import Toolbar, { ERASER_SIZE, Tool } from "./Toolbar/Toolbar";
import { API_ENDPOINT } from "../_Utilities/utils";
import { SnackbarMsg } from "../Lobby/Snackbar/Snackbar";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";

// Once we're in, this set of properties is going ot get passed around to components that care about it
export interface LoggedInProps {
  room: string;
  user: UserI;
}

interface Props extends LoggedInProps {
  notifyUser: (message: SnackbarMsg) => void;
}

interface PropsWithNav extends Props {
  navigate: NavigateFunction;
}

type DrawSocket = Socket<DrawListens, DrawEmits>;

export interface WhiteboardState {
  socket?: DrawSocket;
  whiteBoard?: WhiteboardI;
  drawTracker: DrawTracker;
  roomUsers: { name: string; id: string }[];
  tool?: Tool;
  myId?: string;
}

// So the actual export can be accessed by any name the importer wants to use for the default
// I'll keep it as the file name, but I want to export the class (before the router is injected)
// so I can access the types for fancy typing stuff
export class VirtualWhiteboard extends React.Component<
  PropsWithNav,
  WhiteboardState
> {
  constructor(props: PropsWithNav) {
    super(props);
    // I want to connect to the host as soon as I'm here so I can get info about rooms.
    this.state = {
      drawTracker: { me: { name: "me", drawing: false } },
      roomUsers: [],
      tool: (localStorage.getItem("virtual-wb-tool") as Tool) || "Black",
    };
  }

  setDrawTracker(user: string, changes: Partial<DrawState>) {
    this.setState((prev) => ({
      drawTracker: {
        ...prev.drawTracker,
        [user]: { ...prev.drawTracker[user], ...changes },
      },
    }));
  }

  componentDidMount() {
    this.initializeSocket();
  }

  initializeSocket() {
    // If they created an account without an alias which is only gonna happen with
    // manual account creation
    const user = this.props.user.alias || this.props.user.email;
    const { room } = this.props;
    // redundant when called inside didUpdate, but important in didMount
    // makes sense to put it here so you can never initialize socket without these two
    if (!user || !room) {
      console.warn("Missing info to initialize");
      console.log({ user, room });
      return;
    }
    const token = localStorage.getItem(TOKEN_STORE) || "";
    const socket: DrawSocket = io(API_ENDPOINT, { query: { token } });

    console.log("Starting up socket stuff");
    socket.on("take-users", (roomUsers, drawTracker) =>
      // I want the server's draw tracker info to be merged with my own.
      this.setState({
        roomUsers,
        drawTracker: {
          ...this.state.drawTracker,
          ...drawTracker,
        } as DrawTracker,
      })
    );
    socket.on("take-id", (id) => this.setState({ myId: id }));
    socket.on("take-line-start", (...args) => {
      console.log("Taking line start from server");
      console.log(args);
      this.startLine(...args);
    });
    socket.on("take-line-move", this.continueLine);
    socket.on("take-line-end", (id) =>
      this.setDrawTracker(id, { drawing: false })
    );

    socket.on("take-load-board", (whiteBoard) => {
      console.log("%c\n\nWhiteboard Loaded\n\n", "color: yellow");
      console.log(whiteBoard.lines);
      this.setState({ whiteBoard });
    });
    socket.on("take-name-change", this.changeBoardName);
    socket.on("take-clear", this.clearCanvas);
    socket.on("take-err", (message) => {
      this.props.notifyUser({ type: "error", message });
      // if I haven't loaded my board then I probably want to go back to the
      // select page
      if (!this.state.whiteBoard) this.props.navigate("");
    });
    socket.emit("join-room", room, user);

    this.setState({ socket });
  }

  killSocket() {
    const { socket } = this.state;
    if (socket) {
      socket.disconnect();
      LISTEN_EVENTS.map((e) => socket.off(e));
    }
  }

  componentWillUnmount() {
    this.killSocket();
  }

  startLine = (
    { x, y }: Point,
    id: string,
    name: string,
    lineOptions: Partial<LineFig> = {}
  ) => {
    const lineFig = { ...DEFAULT_LINE, ...lineOptions, points: [x, y] };

    // Only needed if it's me drawing. When other people are erasing, they'll
    // be emitting an event that already has the eraser stuff configured on line fig
    if (id === "me") {
      if (this.state.tool === "Eraser") {
        lineFig.globalCompositeOperation = "destination-out";
        lineFig.strokeWidth = ERASER_SIZE;
      } else lineFig.stroke = this.state.tool;
    }

    if (!this.state.whiteBoard) return;

    this.setState((prevState) => ({
      whiteBoard: prevState.whiteBoard
        ? addLineOnBoard(prevState.whiteBoard, lineFig)
        : prevState.whiteBoard,
    }));

    // Change the draw tracker AFTER adding the new line, so the index points to the right thing
    this.setDrawTracker(id, {
      name,
      drawing: true,
      index: this.state.whiteBoard?.lines.length - 1,
    });
    // If The line I'm starting is me, then emit to the room
    if (id === "me") this.state.socket?.emit("line-start", { x, y }, lineFig);
  };

  continueLine = ({ x, y }: Point, user: string) => {
    const tracker = this.state.drawTracker[user];
    const index = tracker.index;
    // If this source isn't drawing right now
    if (!tracker.drawing || typeof index === "undefined") return;
    this.setState((prevState) => ({
      whiteBoard: prevState.whiteBoard
        ? continueLineOnBoard(prevState.whiteBoard, { x, y }, index)
        : prevState.whiteBoard,
    }));

    // If I'm the source, emit to the room
    if (user === "me") this.state.socket?.emit("line-move", { x, y });
  };

  endLine = (user: string) => {
    this.setDrawTracker(user, { drawing: false });
    if (user === "me") this.state.socket?.emit("line-end");
  };

  changeBoardName = (name: string, emit = false) => {
    console.log("Setting new name to: " + name);
    this.setState((prev) => ({
      whiteBoard: prev.whiteBoard ? { ...prev.whiteBoard, name } : undefined,
    }));
    if (emit) this.state.socket?.emit("name-change", name);
  };

  // to ensure that everyone is in sync I will emit the request, then the server will commit that
  // clear unless someone is still drawing
  clearCanvas = (emit = false) => {
    this.setState((prev) => ({
      whiteBoard: prev.whiteBoard
        ? { ...prev.whiteBoard, lines: [] }
        : undefined,
      // removes all draw trackers, because this just kills all lines ever
      drawTracker: { me: { name: "me", drawing: false } },
    }));
    if (emit) this.state.socket?.emit("clear");
  };

  render() {
    const { roomUsers, whiteBoard, myId, tool } = this.state;

    return (
      <div className={styles.container}>
        {!whiteBoard ? (
          <span className={styles["board-name"]}>Loading...</span>
        ) : (
          <input
            className={styles["board-name"]}
            onChange={(e) => {
              if (e.target.value.length > 30)
                e.target.value = e.target.value.slice(0, 30);
              this.changeBoardName(e.target.value, true);
            }}
            value={whiteBoard.name}
          />
        )}

        {whiteBoard && (
          <div className={styles["body"]}>
            <Link to="/" className={styles["big-home-button"]}>
              Back To Lobby
            </Link>
            <Toolbar
              users={roomUsers}
              myId={myId || ""}
              tool={tool || "Black"}
              setTool={(t) => {
                localStorage.setItem("virtual-wb-tool", t);
                this.setState({ tool: t });
              }}
              clearCanvas={() => this.clearCanvas(true)}
              {...this.props}
            />
            <KonvaWrapper
              startLine={this.startLine}
              continueLine={this.continueLine}
              endLine={this.endLine}
              {...this.state}
            />
            <Link to="/" className={styles["little-home-button"]}>
              Back To Lobby
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default function RouterWrapped(props: Props) {
  const navigate = useNavigate();
  return <VirtualWhiteboard navigate={navigate} {...props} />;
}

// Meium article that turned me on to konva
// https://medium.com/bb-tutorials-and-thoughts/how-to-implement-drawing-in-react-app-in-typescript-520cbba2c651
// Konva free-draw demo: ------------------ https://konvajs.org/docs/sandbox/Free_Drawing.html
// Konva docs: ---------------------------- https://konvajs.org/docs
// Totorial from WebDev Simplified -------- https://www.youtube.com/watch?v=iRaelG7v0OU
