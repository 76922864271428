import React, { useState } from "react";
import { GoogleLogout } from "react-google-login";
import CloseIcon from "../_Utilities/Icons/CloseIcon";
import Settings from "../_Utilities/Icons/Settings";
import MyForm from "../_Utilities/MyForm/MyForm";
import { FormProps, SubmitMessage } from "../_Utilities/MyForm/FormTypes";
import { ReactSetter } from "../_Utilities/ReactAliases";
import { easyStyles, httpProm, API_ENDPOINT } from "../_Utilities/utils";
import { CustomizableFields, TOKEN_STORE, UserI } from "../data-types";
import { GOOGLE_CLIENT_ID } from "../Lobby/LoginForm/GoogleButton";
import styles from "./AccountManagement.module.scss";

interface Props {
  user: UserI;
  setUser: ReactSetter<UserI | null>;
  className?: string;
}

export default function AccountManagement({ user, setUser, className }: Props) {
  const [deleteState, setDeleteState] = useState("");
  const [management, setManagement] = useState(false);
  const [modifyForm, setModForm] = useState<CustomizableFields>({
    alias: user.alias,
  });
  const [err, setErr] = useState<SubmitMessage>();
  const [loading, setLoading] = useState(false);
  function logout() {
    const token = localStorage.getItem(TOKEN_STORE) as string;
    // I need the token to prove I'm really logged in
    httpProm(`${API_ENDPOINT}logout`, { query: { token } }).then(
      (suc) => setUser(null),
      console.error
    );
  }

  function saveChanges() {
    const token = localStorage.getItem(TOKEN_STORE) as string;
    setLoading(true);
    httpProm(`${API_ENDPOINT}modify-account`, {
      query: { token },
      method: "POST",
      body: modifyForm,
    }).then(
      () => {
        const saveNDone = () => {
          setUser((u) => u && { ...u, ...modifyForm });
          setLoading(false);
          setManagement(false);
        };
        if (process.env.NODE_ENV !== "production") {
          // want to test to see what this is like
          setTimeout(saveNDone, 1000);
        } else saveNDone();
      },
      (err) => setErr({ type: "error", message: err.text })
    );
  }

  function deleteAccount() {
    setDeleteState("Deleting...");
    const done = () => {
      setDeleteState("");
      setUser(null);
    };
    const token = localStorage.getItem(TOKEN_STORE) as string;
    httpProm(`${API_ENDPOINT}delete-account`, { query: { token } }).then(
      () => {
        if (process.env.NODE_ENV !== "production") {
          // want to test to see what this is like
          setTimeout(done, 1000);
        } else done();
      },
      (err) => {
        setDeleteState("");
        setErr({ type: "error", message: err.text });
      }
    );
  }

  function closeAndSave() {
    setManagement(false);
    saveChanges();
  }

  const formProps: FormProps<CustomizableFields> = {
    form: modifyForm,
    setForm: setModForm,
    onSubmit: saveChanges,
    actionButton: loading ? "Saving..." : "Save Changes",
    submitMessage: err,
    options: {
      alias: {
        validator: (val) => (!val?.length ? "Alias cannot be blank" : ""),
      },
    },
  };

  return (
    <>
      <div className={`${styles.logout} ${className}`}>
        <div className={styles.user}>
          Logged in as: {user.alias || user.email}
        </div>
        <div className={styles["button-container"]}>
          {user.linkedAccount === "google" ? (
            // Renders an identical button, but I have to use this component to access the google functionality
            <GoogleLogout
              clientId={GOOGLE_CLIENT_ID}
              onLogoutSuccess={logout}
              render={() => <button onClick={logout}>Logout</button>}
            />
          ) : (
            <button onClick={logout}>Logout</button>
          )}
          <button
            className={easyStyles(styles, "settings", "alt")}
            onClick={() => setManagement(true)}>
            <Settings className={styles.icon} />
          </button>
        </div>
      </div>
      {management && (
        <div className={styles.modal} onClick={() => closeAndSave()}>
          <div
            className={styles["modal-body"]}
            onClick={(e) => e.stopPropagation()}>
            <header>
              <h1>Account Management</h1>
              <button onClick={() => closeAndSave()}>
                <CloseIcon />
              </button>
            </header>
            <div className={styles["email-line"]}>
              <label>Email: {user.email}</label>
            </div>
            <MyForm {...formProps}>
              <button
                className={easyStyles(styles, "alt")}
                onClick={(e) => {
                  e.preventDefault();
                  if (!deleteState) return setDeleteState("Yes");
                  if (deleteState === "Yes") return deleteAccount();
                }}
                onBlur={() => setDeleteState("")}>
                {deleteState ? deleteState : "Delete Account"}
              </button>
            </MyForm>
            {deleteState && (
              <div>Are you sure you want to delete your account?</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
