import React, { useState } from "react";
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { httpProm, HTTP_Res, API_ENDPOINT } from "../../_Utilities/utils";
import styles from "./LoginForm.module.scss";

// Google Sign in front end steps, but I swapped the dumb import for the cool react-google-login package
// https://developers.google.com/identity/sign-in/web/sign-in

export const GOOGLE_CLIENT_ID =
  "402569394606-tefprnetdgug8pgffgvp1seojb5qqp47.apps.googleusercontent.com";

interface Props {
  loginSuccess: (res: HTTP_Res) => void;
  displayErr: (res: HTTP_Res) => void;
}

export default function GoogleButton({ loginSuccess, displayErr }: Props) {
  const [linkModal, setLinkModal] = useState(false);
  const [loginBody, setLoginBody] = useState<{
    idToken: string;
    alias: string;
    email: string;
  }>();

  function onSignIn(
    googleUser: GoogleLoginResponse | GoogleLoginResponseOffline
  ) {
    console.log("Google On Sign in!");
    // the "code" property is the flag react-google-login uses to indicate an offline response
    // can check type definitions to see this
    const { code } = googleUser;
    if (code) return console.log("Google says I'm offline, code: " + code);
    // can cast type because I know ti's good now. Kinda wish typescript had better functionality for this kind of thing
    const user = googleUser as GoogleLoginResponse;
    console.log("doing sign in");
    // I can set my alias to this value to signify that I've logged in
    var profile = user.getBasicProfile();

    const body = {
      idToken: user.getAuthResponse().id_token,
      alias: profile.getName(),
      email: profile.getEmail(),
    };
    // I'm setting this so I can access it later in the confirm link method if that comes up
    setLoginBody(body);
    httpProm(`${API_ENDPOINT}google-signin`, { method: "POST", body }).then(
      (suc) => {
        // Account exists...
        if (suc.status === 202) return setLinkModal(true);
        console.log("Login was successful");
        return loginSuccess(suc);
      },
      displayErr
    );
  }

  function linkAccounts() {
    const body = { ...loginBody, linkme: true };
    httpProm(`${API_ENDPOINT}google-signin`, { method: "POST", body }).then(
      (suc) => loginSuccess(suc),
      displayErr
    );
  }

  return (
    <>
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        onSuccess={onSignIn}
        onFailure={(x) => console.log(x)}
        cookiePolicy={"single_host_origin"}
      />
      {linkModal && (
        <div className={styles["modal"]}>
          <div className={styles["modal-body"]}>
            <p>
              An account with the email{" "}
              {loginBody?.email || "something's wrong..."} already exists, would
              you like to link that account to google. All current data will be
              transferred, but if you do you will only be able to sign in with
              google in the future.
            </p>
            <button onClick={linkAccounts}>Link Account</button>
            <button className={styles.alt} onClick={() => setLinkModal(false)}>
              Never mind
            </button>
          </div>
        </div>
      )}
    </>
  );
}
